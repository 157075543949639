@mixin Inv {
	$w: 256px;
	$imgw: $w/6;
	.inventory {
		position: fixed;
		left: -$w;
		width: $w;
		bottom: 16px + 16px + 32px;
		overflow: hidden;
		height: $imgw*6 + 16px*6;
		transition: 0.2s left ease-in-out;
		color: white;
		background: rgba(0,0,0,0.8);
		border-top-right-radius: 0px;
		&.inventory-open--true {
			left: 16px;
		}

		&--1155 {
			height: $imgw*7 + 16px*7;
		}
	}
	.inv-item {
		box-sizing: border-box;
		
		padding: 8px;
		&--nft {

		}
		&--token {

		}
	}
	img.nft-thumb {
		height: $imgw;
		width: $imgw;
		vertical-align: middle; 
	}
	img.token-icon {
		height: $imgw;
		width: $imgw;
		border-radius: $imgw;
		overflow: hidden;
		vertical-align: middle;
		filter: drop-shadow(0 0 4px white); 
	}
	.inv-bal {
		display: inline-block;
		vertical-align: middle; 
		margin-left: 16px;

		span {
			color: #6e6e6e;
		}
	}
}